import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../css/Navbar2.css";

function Navbar2() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isActive = (path) => {
    // Si la ruta es la raíz y el enlace es Inicio, devuelve true
    if (location.pathname === "/" && path === "/inicio") {
      return true;
    }
    // De lo contrario, compara con la ruta actual
    return location.pathname === path;
  };
  return (
    <>
      <nav className={`navbar2 ${isOpen ? "open" : ""}`}>
        <div className="nav-items2">
          <div className="hamburger2" onClick={() => setIsOpen(!isOpen)}>
            &#9776;
          </div>
          <a
            href="/inicio"
            className={isActive("/inicio") ? "active" : ""}
            onClick={() => setIsOpen(false)}>
            Inicio
          </a>

          <a
            href="/quienes_somos"
            className={isActive("/quienes_somos") ? "active" : ""}
            onClick={() => setIsOpen(false)}>
            ¿Quiénes somos?
          </a>
          <a
            href="/proyectos"
            className={isActive("/proyectos") ? "active" : ""}
            onClick={() => setIsOpen(false)}>
            Proyectos
          </a>
          <a
            href="/servicios"
            className={isActive("/servicios") ? "active" : ""}
            onClick={() => setIsOpen(false)}>
            Servicios
          </a>
          <a
            href="/investigacion"
            className={isActive("/investigacion") ? "active" : ""}
            onClick={() => setIsOpen(false)}>
            Líneas de Investigación
          </a>
          <a
            href="/noticias"
            className={isActive("/noticias") ? "active" : ""}
            onClick={() => setIsOpen(false)}>
            Noticias
          </a>
          <a
            href="/monitoreo"
            className={isActive("/monitoreo") ? "active" : ""}
            onClick={() => setIsOpen(false)}>
            Monitoreo de uso de suelo agrícola
          </a>
        </div>
      </nav>
    </>
  );
}

export default Navbar2;
