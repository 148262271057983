import React, { useState, useEffect } from "react";
import "../css/Panel_Visualizadores.css";
import axios from "axios";
import borde1 from "../images/bordes_region.png";
import borde2 from "../images/bordes_elqui.png";
import borde3 from "../images/bordes_limari.png";
import borde4 from "../images/bordes_choapa.png";

function Panel_Visualizadores() {
  // Estado para el URL del iframe
  const [links, setLinks] = useState([]);
  const [iframeUrl, setIframeUrl] = useState("");

  // Función para cambiar el URL al hacer clic en una provincia
  const cambiarIframe = (url) => {
    setIframeUrl(url);
  };

  useEffect(() => {
    const fetchVisualizadores = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const initialUrl = queryParams.get("url");
      if (initialUrl) {
        setIframeUrl(initialUrl);
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/visualizador.php`
      );
      setLinks(response.data);
      // Establece la URL por defecto al primer link
    };

    fetchVisualizadores();
  }, []);

  return (
    <div className="panelVisualizadores">
      <h1>Monitoreo de uso de suelo agrícola</h1>
      <h2>2024</h2>
      <div className="selectorZona">
        {links.map((link, index) => (
          <div
            key={index}
            className="botonRegion"
            onClick={() => cambiarIframe(link.linkRegion)}>
            <div className="region">
              <img src={borde1}></img>
            </div>
            <h1>Región de Coquimbo, Chile</h1>
          </div>
        ))}
        <div className="botonesProvincia">
          {links.map((link, index) => (
            <div
              key={index}
              className="provincia1"
              onClick={() => cambiarIframe(link.linkElqui)}>
              <img src={borde2}></img>
              <h1>Provincia Elqui</h1>
            </div>
          ))}
          {links.map((link, index) => (
            <div
              key={index}
              className="provincia2"
              onClick={() => cambiarIframe(link.linkLimari)}>
              <img src={borde3}></img>
              <h1>Provincia Limarí</h1>
            </div>
          ))}
          {links.map((link, index) => (
            <div
              key={index}
              className="provincia3"
              onClick={() => cambiarIframe(link.linkChoapa)}>
              <img src={borde4}></img>
              <h1>Provincia Choapa</h1>
            </div>
          ))}
        </div>
      </div>
      <div>
        <iframe src={iframeUrl} loading="lazy"></iframe>
      </div>
    </div>
  );
}

export default Panel_Visualizadores;
