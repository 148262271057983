import "../css/Inicio.css";
import Navbar1 from "../components/Navbar1";
import Navbar2 from "../components/Navbar2";
import Panel_Proyectos from "../components/Panel_Proyectos";
import Footer from "../components/Footer";

export default function Proyectos() {
  return (
    <div className="Proyectos">
      <Navbar1 />
      <Navbar2 />
      <Panel_Proyectos />
      <Footer />
    </div>
  );
}
