import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import { CSSTransition } from "react-transition-group";
import "../css/Panel_Investigacion.css";
import axios from "axios";

function Panel_Investigacion() {
  const [index, setIndex] = useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [laboratorio, setLaboratorio] = useState([]);
  const [laboratorio_2, setLaboratorio_2] = useState([]);
  const [laboratorio_3, setLaboratorio_3] = useState([]);
  const [investigacion, setInvestigacion] = useState([]);

  const fetchLaboratorio = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/laboratorio.php`
    );
    setLaboratorio(response.data);
  };

  const fetchLaboratorio_2 = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/laboratorio_2.php`
    );
    setLaboratorio_2(response.data);
  };

  const fetchLaboratorio_3 = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/laboratorio_3.php`
    );
    setLaboratorio_3(response.data);
  };

  const fetchInvestigacion = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/investigacion.php`
    );
    setInvestigacion(response.data);
  };

  useEffect(() => {
    fetchLaboratorio();
    fetchLaboratorio_2();
    fetchLaboratorio_3();
    fetchInvestigacion();
  }, []);

  return (
    <div className="panelLineas">
      <div className="selectorLineas">
        <button
          className={index === 0 ? "active" : ""}
          onClick={() => handleChangeIndex(0)}>
          Laboratorio
        </button>
        <button
          className={index === 1 ? "active" : ""}
          onClick={() => handleChangeIndex(1)}>
          Lineas de Investigación
        </button>
      </div>
      <SwipeableViews
        className="swipeableLineas"
        enableMouseEvents
        index={index}
        onChangeIndex={handleChangeIndex}>
        <div className="primeraPestaña">
          <div className="panelCentrado1">
            <h2>¿Qué hacemos en el laboratorio Prommra?</h2>
            {laboratorio.map((laboratorio) => (
              <div key={laboratorio.id}>
                <h2 className="añoLinea">{laboratorio.year}</h2>
                <p>{laboratorio.presentacion}</p>
              </div>
            ))}

            <div className="imagenesPrimeraPestañas">
              {laboratorio_2.map((laboratorio_2) => (
                <div className="imagenPrommra" key={laboratorio_2.id}>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/${laboratorio_2.imagen}`}
                    onClick={() => {
                      setIsModalOpen(true);
                      setSelectedImage(
                        `${process.env.REACT_APP_API_URL}/uploads/${laboratorio_2.imagen}`
                      );
                    }}
                    loading="lazy"></img>
                </div>
              ))}
            </div>
            <div className="principios">
              {laboratorio_3.map((laboratorio_3) => (
                <div className="principio" key={laboratorio_3.id}>
                  <h3>{laboratorio_3.titulo}</h3>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/${laboratorio_3.imagen}`}
                    onClick={() => {
                      setIsModalOpen(true);
                      setSelectedImage(
                        `${process.env.REACT_APP_API_URL}/uploads/${laboratorio_3.imagen}`
                      );
                    }}
                    loading="lazy"></img>
                  <p>{laboratorio_3.descripcion}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="segundaPestaña">
          <h2>Líneas de investigación</h2>
          {laboratorio.map((laboratorio) => (
            <div key={laboratorio.id}>
              <h2 className="añoLinea">{laboratorio.year}</h2>
            </div>
          ))}
          <div className="lineas">
            {investigacion.map((investigacion) => (
              <div className="linea" key={investigacion.id}>
                <h3>{investigacion.titulo}</h3>
                <img
                  src={`${process.env.REACT_APP_API_URL}/uploads/${investigacion.imagen}`}
                  onClick={() => {
                    setIsModalOpen(true);
                    setSelectedImage(
                      `${process.env.REACT_APP_API_URL}/uploads/${investigacion.imagen}`
                    );
                  }}
                  loading="lazy"></img>
                <p>{investigacion.descripcion}</p>
              </div>
            ))}
          </div>
        </div>
      </SwipeableViews>
      <CSSTransition
        in={isModalOpen && selectedImage !== null}
        timeout={300}
        classNames="modal"
        unmountOnExit>
        <div className="modalGeneral" onClick={() => setIsModalOpen(false)}>
          <div
            className="modalContentGeneral"
            onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} loading="lazy"></img>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Panel_Investigacion;
