import React from "react";
import "../css/Inicio.css";
import Navbar1 from "../components/Navbar1";
import Navbar2 from "../components/Navbar2";
import Banner from "../components/Banner";
import Panel_Inicio from "../components/Panel_Inicio";
import Footer from "../components/Footer";
import AvisoConstruccion from "../components/AvisoConstruccion";

function Inicio() {
  return (
    <div className="Inicio">
      {/*<AvisoConstruccion />*/}
      <Navbar1 />
      <Navbar2 />
      <div className="backgroundPanel">
        <Banner />
        <Panel_Inicio />
      </div>
      <Footer />
    </div>
  );
}

export default Inicio;
