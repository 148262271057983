import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import error from "../images/imagen-no-disponible.jpeg";
import "../css/Panel_Noticias.css";

const Panel_Noticias = () => {
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [noticias, setNoticias] = useState([]);

  const [selectedNoticia, setSelectedNoticia] = useState(null);

  const fetchNoticias = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/noticias.php`
    );
    setNoticias(response.data);
  };

  const handleBodyScroll = (isOpen) => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Deshabilita el desplazamiento
    } else {
      document.body.style.overflow = "auto"; // Habilita el desplazamiento
    }
  };

  useEffect(() => {
    handleBodyScroll(isModalOpen2);
    fetchNoticias();

    return () => {
      document.body.style.overflow = "auto"; // Asegura que el desplazamiento se habilite al salir
    };
  }, [isModalOpen2]);

  return (
    <div className="panelNoticias">
      <div className="noticias">
        {noticias.map((noticia) => (
          <div
            className="noticia"
            key={noticia.id}
            onClick={() => {
              setIsModalOpen2(true);
              setSelectedNoticia(noticia);
            }}>
            <div className="contenedorImagenNoticia">
              <img
                src={`${process.env.REACT_APP_API_URL}/uploads/${noticia.imagen}`}
                onError={(e) => (e.target.src = error)}
                loading="lazy"
              />
            </div>
            <div className="textoNoticia">
              <h1>{noticia.titulo}</h1>
              {noticia?.fecha !== "0000-00-00" && <h2>{noticia?.fecha}</h2>}
              <p>{noticia.enunciado}</p>
            </div>
          </div>
        ))}
      </div>
      <CSSTransition
        in={isModalOpen2 && selectedNoticia !== null}
        timeout={300}
        classNames="modal"
        unmountOnExit>
        <div className="modal" onClick={() => setIsModalOpen2(false)}>
          <div
            className="modal-noticias"
            onClick={(e) => e.stopPropagation()}
            key={selectedNoticia?.id}>
            <h1>{selectedNoticia?.titulo}</h1>
            {selectedNoticia?.fecha !== "0000-00-00" && (
              <h2>{selectedNoticia?.fecha}</h2>
            )}
            <img
              src={`${process.env.REACT_APP_API_URL}/uploads/${selectedNoticia?.imagen}`}
              loading="lazy"></img>
            <div className="textoMiembro">
              <i>{selectedNoticia?.enunciado}</i>
              <p>{selectedNoticia?.descripcion}</p>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Panel_Noticias;
