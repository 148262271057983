import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import "../css/Panel_Inicio.css";
import axios from "axios";
import Reporte from "./Reporte";

function Panel_Inicio() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedNoticia, setSelectedNoticia] = useState(null);

  const [noticias, setNoticias] = useState([]);
  const [redes, setRedes] = useState([]);
  const [reportes, setReportes] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [prensa, setPrensa] = useState([]);

  const fetchNoticias = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/noticias.php`
    );
    setNoticias(response.data);
  };

  const fetchRedes = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/redes.php`
    );
    setRedes(response.data);
  };

  const fetchReportes = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/reportes.php`
    );
    setReportes(response.data);
  };

  const fetchEventos = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/eventos.php`
    );
    setEventos(response.data);
  };

  const fetchPrensa = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/prensa.php`
    );
    setPrensa(response.data);
  };

  const handleBodyScroll = (isOpen) => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Deshabilita el desplazamiento
    } else {
      document.body.style.overflow = "auto"; // Habilita el desplazamiento
    }
  };

  useEffect(() => {
    handleBodyScroll(isModalOpen2);

    fetchNoticias();
    fetchRedes();
    fetchReportes();
    fetchEventos();
    fetchPrensa();

    return () => {
      document.body.style.overflow = "auto"; // Asegura que el desplazamiento se habilite al salir
    };
  }, [isModalOpen2]);

  return (
    <div className="mainPanel">
      <div className="leftPanel">
        <hr className="separadorPrincipal"></hr>

        {/*
        <hr className="separadorPrincipal"></hr>
        <h1 className="tituloPrincial">Reporte de Embalses</h1>
        {reportes.map((reporte) => (
          <img
            className="reporte_embalses"
            src={`${process.env.REACT_APP_API_URL}/uploads/${reporte.imagen}`}
            onClick={() => {
              setIsModalOpen(true);
              setSelectedImage(
                `${process.env.REACT_APP_API_URL}/uploads/${reporte.imagen}`
              );
            }}
            loading="lazy"
            key={reporte.id}
            alt="Reporte de Embalses"></img>
        ))}
            */}
        <Reporte />
      </div>
      <div className="centralPanel">
        <hr className="separadorPrincipal"></hr>
        <h1 className="tituloPrincialNoticias">Noticias</h1>

        <div className="centralPanelNoticias">
          {noticias.slice(0, 8).map((noticia) => {
            const formattedDate =
              noticia.fecha === "0000-00-00" || !noticia.fecha
                ? ""
                : noticia.fecha;
            return (
              <div
                className="contenedorNoticias"
                key={noticia.id}
                onClick={() => {
                  setIsModalOpen2(true);
                  setSelectedNoticia(noticia);
                }}>
                <h1 className="tituloNoticias">{noticia.titulo}</h1>
                {formattedDate && (
                  <h3 className="fechaNoticia">{formattedDate}</h3>
                )}

                <div className="imagenDescripcion">
                  <img
                    className="imagenNoticia"
                    src={`${process.env.REACT_APP_API_URL}/uploads/${noticia.imagen}`}
                    alt={noticia.titulo}
                  />

                  {/*
                  <p className="descripcionNoticia">{noticia.enunciado}</p>
                   */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="rightPanel">
        <hr className="separadorPrincipal"></hr>
        <h1 className="tituloPrincial">Próximos eventos</h1>
        <div className="contenedorEventos">
          {eventos.map((evento) => (
            <img
              className="imagenEventos"
              src={`${process.env.REACT_APP_API_URL}/uploads/${evento.imagen}`}
              onClick={() => {
                setIsModalOpen(true);
                setSelectedImage(
                  `${process.env.REACT_APP_API_URL}/uploads/${evento.imagen}`
                );
              }}
              loading="lazy"
              key={evento.id}
              alt="Evento"></img>
          ))}
        </div>
        <hr className="separadorPrincipal"></hr>
        <h1 className="tituloPrincial">Plataformas</h1>
        <div className="redesSociales">
          {redes.map((red) => (
            <a href={red.link} target="_blank" key={red.id}>
              <img
                src={`${process.env.REACT_APP_API_URL}/uploads/${red.imagen}`}
                loading="lazy"
                alt={red.titulo}></img>

              <div className="plataformas">
                <h1>
                  <strong>{red.titulo}</strong>
                </h1>
                {red.subtitulo !== "" && <p>{red.subtitulo}</p>}
              </div>
            </a>
          ))}
        </div>

        <hr className="separadorPrincipal"></hr>
        <h1 className="tituloPrincial">Prommra en Prensa</h1>
        {prensa.map((prensa) => (
          <div className="prensa" key={prensa.id}>
            <img
              className="imagenPrensa"
              src={`${process.env.REACT_APP_API_URL}/uploads/${prensa.imagen}`}
              loading="lazy"
              alt="Prensa"></img>
            <a href={prensa.link} className="tituloNoticas" target="_blank">
              {prensa.titulo}
            </a>
          </div>
        ))}
      </div>
      <CSSTransition
        in={isModalOpen && selectedImage !== null}
        timeout={300}
        classNames="modal"
        unmountOnExit>
        <div className="modalGeneral" onClick={() => setIsModalOpen(false)}>
          <div
            className="modalContentGeneral"
            onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} loading="lazy" alt="Modal Content" />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={isModalOpen2 && selectedNoticia !== null}
        timeout={300}
        classNames="modal"
        unmountOnExit>
        <div className="modal" onClick={() => setIsModalOpen2(false)}>
          <div
            className="modal-noticias"
            onClick={(e) => e.stopPropagation()}
            key={selectedNoticia?.id}>
            <h1>{selectedNoticia?.titulo}</h1>
            {selectedNoticia?.fecha !== "0000-00-00" && (
              <h2>{selectedNoticia?.fecha}</h2>
            )}
            <img
              src={`${process.env.REACT_APP_API_URL}/uploads/${selectedNoticia?.imagen}`}
              loading="lazy"
              alt="Noticia"
            />
            <div className="textoMiembro">
              <i>{selectedNoticia?.enunciado}</i>
              <p>{selectedNoticia?.descripcion}</p>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Panel_Inicio;
