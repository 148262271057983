import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/Banner.css";
import axios from "axios";

const ImageSlider = () => {
  const [slider, setSlider] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Adjust the pause between scrolls here (in milliseconds)
  };

  const fetchSlider = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/slider.php`
      );
      setSlider(response.data);
    } catch (error) {
      console.error("Error fetching slider data:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    fetchSlider();
    // Limpieza del evento
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slider.map((slide, index) => (
          <div key={index} className="slider-item">
            <div className="flex-container">
              <img
                src={
                  windowWidth < 1000
                    ? `${process.env.REACT_APP_API_URL}/uploads/${slide.imagenCelular}`
                    : `${process.env.REACT_APP_API_URL}/uploads/${slide.imagen}`
                }
                alt={`Slide ${index + 1}`}
                className="slider-image"
                loading="lazy"
              />
              <div className="overlay">
                <p>{slide.titulo}</p>
                <a className="saberMas" href="/servicios">
                  Saber Más
                </a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
