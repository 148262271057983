import "../css/Inicio.css";
import Navbar1 from "../components/Navbar1";
import Navbar2 from "../components/Navbar2";
import Panel_Contactanos from "../components/Panel_Contactanos";
import Footer from "../components/Footer";

export default function Contactanos() {
  return (
    <div className="Contactanos">
      <Navbar1 />
      <Navbar2 />
      <Panel_Contactanos />
      <Footer />
    </div>
  );
}
