import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import { CSSTransition } from "react-transition-group";
import "../css/Panel_Miembros.css";
import error from "../images/imagen-no-disponible.jpeg";
import logo from "../images/logo-prommra-cuadrado.png";
import axios from "axios";

function Panel_Miembros() {
  const [index, setIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [miembros, setMiembros] = useState([]);
  const [que_hacemos, setQue_hacemos] = useState([]);
  const [que_hacemos_2, setQue_hacemos_2] = useState([]);
  const [que_hacemos_3, setQue_hacemos_3] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  const handleBodyScroll = (isOpen) => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Deshabilita el desplazamiento
    } else {
      document.body.style.overflow = "auto"; // Habilita el desplazamiento
    }
  };

  const fetchMiembros = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/miembros.php`
    );
    setMiembros(response.data);
  };

  const fecthQue_hacemos = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/que_hacemos.php`
    );
    setQue_hacemos(response.data);
  };

  const fecthQue_hacemos_2 = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/que_hacemos_2.php`
    );
    setQue_hacemos_2(response.data);
  };

  const fecthQue_hacemos_3 = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/que_hacemos_3.php`
    );
    setQue_hacemos_3(response.data);
  };

  useEffect(() => {
    handleBodyScroll(isModalOpen);

    fetchMiembros();
    fecthQue_hacemos();
    fecthQue_hacemos_2();
    fecthQue_hacemos_3();

    return () => {
      document.body.style.overflow = "auto"; // Asegura que el desplazamiento se habilite al salir
    };
  }, [isModalOpen]);

  return (
    <div className="panel">
      <div className="selectorMiembros">
        <button
          className={index === 0 ? "active" : ""}
          onClick={() => handleChangeIndex(0)}>
          Equipo Prommra
        </button>
        <button
          className={index === 1 ? "active" : ""}
          onClick={() => handleChangeIndex(1)}>
          ¿Qué hacemos?
        </button>
      </div>
      <SwipeableViews
        index={index}
        onChangeIndex={handleChangeIndex}
        enableMouseEvents>
        <div className="equipo">
          {miembros.map((miembro) => (
            <div
              className="miembro"
              key={miembro.id}
              onClick={() => {
                setIsModalOpen(true);
                setSelectedMember(miembro);
              }}>
              <img
                src={`${process.env.REACT_APP_API_URL}/uploads/${miembro.imagen}`}
                onError={(e) => (e.target.src = error)}
                loading="lazy"
              />
              <div className="textoMiembro">
                <h1>{miembro.cargo}</h1>
                <h2>{miembro.nombre}</h2>
                <p>{miembro.profesion}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="objetivo">
          <img src={logo} loading="lazy" />
          {que_hacemos.map((que_hacemos) => (
            <div className="infoPrommra" key={que_hacemos.id}>
              <p>{que_hacemos.presentacion}</p>
              <h2>Historia</h2>
              <p>{que_hacemos.historia}</p>
              <h2>Infraestructura</h2>
              <p>{que_hacemos.infraestructura}</p>
              <h2>¿Qué hacemos?</h2>
              <p>{que_hacemos.que_hacemos}</p>
              <h2>Líneas de trabajo</h2>
              <ul>
                {que_hacemos_2.map((que_hacemos_2) => (
                  <li key={que_hacemos_2.id}>{que_hacemos_2.linea}</li>
                ))}
              </ul>
              <h2>Servicios</h2>
              <ul>
                {que_hacemos_3.map((que_hacemos_3) => (
                  <li key={que_hacemos_3.id}>{que_hacemos_3.servicio}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </SwipeableViews>
      <CSSTransition
        in={isModalOpen && selectedMember !== null}
        timeout={300}
        classNames="modal"
        unmountOnExit>
        <div className="modal" onClick={() => setIsModalOpen(false)}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            key={selectedMember?.id}>
            <img
              src={`${process.env.REACT_APP_API_URL}/uploads/${selectedMember?.imagen}`}
              onError={(e) => (e.target.src = error)}
              loading="lazy"></img>
            <div className="textoMiembro">
              <h1>{selectedMember?.cargo}</h1>
              <h2>{selectedMember?.nombre}</h2>
              <p className="titulo">{selectedMember?.profesion}</p>
              <p className="descripcion">{selectedMember?.descripcion}</p>
              <p className="correo">Contacto: {selectedMember?.correo}</p>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Panel_Miembros;
