import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import "../css/Panel_Servicios.css";
import error from "../images/imagen-no-disponible.jpeg";
import axios from "axios";

function Panel_Servicios() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [servicios, setServicios] = useState([]);

  const fetchServicios = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/servicios.php`
    );
    setServicios(response.data);
  };

  useEffect(() => {
    fetchServicios();
  }, []);

  return (
    <div className="panelServicios">
      <div className="grupo_servicios">
        {servicios.map((servicio) => (
          <div className="servicio_individual" key={servicio.id}>
            <img
              src={`${process.env.REACT_APP_API_URL}/uploads/${servicio.imagen}`}
              onError={(e) => (e.target.src = error)}
              onClick={() => {
                setIsModalOpen(true);
                setSelectedImage(
                  `${process.env.REACT_APP_API_URL}/uploads/${servicio.imagen}`
                );
              }}
              loading="lazy"
            />
            <div className="textoServicio">
              <h1>{servicio.titulo}</h1>
              <p>{servicio.descripcion}</p>
            </div>
          </div>
        ))}
      </div>
      <CSSTransition
        in={isModalOpen && selectedImage !== null}
        timeout={300}
        classNames="modal"
        unmountOnExit>
        <div className="modalGeneral" onClick={() => setIsModalOpen(false)}>
          <div
            className="modalContentGeneral"
            onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedImage}
              onError={(e) => (e.target.src = error)}
              loading="lazy"></img>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Panel_Servicios;
