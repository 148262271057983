import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inicio from "./pages/Inicio";
import Quienes_somos from "./pages/Quienes_somos";
import Noticias from "./pages/Noticias";
import Proyectos from "./pages/Proyectos";
import Servicios from "./pages/Servicios";
import Monitoreo from "./pages/Monitoreo";
import Visualizadores from "./pages/Visualizadores";
import Investigacion from "./pages/Investigacion";
import Contactanos from "./pages/Contactanos";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/AuthContext"; // Importar AuthProvider
import "./App.css";

export default function App() {
  return (
    <div>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={<Inicio />}></Route>
            <Route path="/inicio" element={<Inicio />}></Route>
            <Route path="/noticias" element={<Noticias />}></Route>
            <Route path="/quienes_somos" element={<Quienes_somos />}></Route>
            <Route path="/proyectos" element={<Proyectos />}></Route>
            <Route path="/servicios" element={<Servicios />}></Route>
            <Route path="/monitoreo" element={<Monitoreo />}></Route>
            <Route path="/visualizadores" element={<Visualizadores />}></Route>

            <Route path="/investigacion" element={<Investigacion />}></Route>
            <Route path="/contactanos" element={<Contactanos />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<h1>Error 404: Not found</h1>}></Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}
