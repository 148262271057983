import "../css/Inicio.css";
import Navbar1 from "../components/Navbar1";
import Navbar2 from "../components/Navbar2";
import Panel_Visualizadores from "../components/Panel_Visualizadores";
import Footer from "../components/Footer";

export default function Visualizadores() {
  return (
    <div className="Visualizadores">
      <Navbar1 />
      <Navbar2 />
      <Panel_Visualizadores />
      <Footer />
    </div>
  );
}
