import "../css/Inicio.css";
import Navbar1 from "../components/Navbar1";
import Navbar2 from "../components/Navbar2";
import Panel_Noticias from "../components/Panel_Noticias";
import Footer from "../components/Footer";

export default function Noticias() {
  return (
    <div className="panelPrincipal">
      <Navbar1 />
      <Navbar2 />
      <Panel_Noticias />
      <Footer />
    </div>
  );
}
