import React, { useState, useEffect } from "react";
import "../css/Panel_Monitoreo.css";
import axios from "axios";
import region from "../images/region de coquimbo.png";
import region2 from "../images/region_ancha.jpg";
import provincia1 from "../images/provincia_elqui.png";
import provincia2 from "../images/provincia_limari.png";
import provincia3 from "../images/provincia_choapa.png";
import fondo1 from "../images/fondo_elqui.png";
import fondo2 from "../images/fondo_limari.png";
import fondo3 from "../images/fondo_choapa.png";
import icono1 from "../images/icono_elqui.png";
import icono2 from "../images/icono_limari.png";
import icono3 from "../images/icono_choapa.png";

function Panel_Monitoreo() {
  const [Url, setUrl] = useState([]);
  const fetchUrl = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/visualizador.php`
    );
    setUrl(response.data);
  };

  useEffect(() => {
    fetchUrl();
  }, []);

  return (
    <div className="panelMonitoreo">
      {Url.map((url, index) => (
        <div key={index} className="panelRegion">
          <a href={`/visualizadores?url=${url.linkRegion}`}>
            <img src={region2}></img>
          </a>
        </div>
      ))}
      <div className="panelZonas">
        <h1>Monitoreo de uso de suelo agrícola</h1>
        <p>
          La sección representa la información de la dinámica de la superficie
          cultivada de la región, con énfasis en las zonas de bajo riesgo. En
          esta sección encontrará la evolución de la superficie cultivada a
          partir de la temporada 2000 a la última temporada finalizada, con el
          desglose en 4 clases de cultivos (frutales, caducos, frutales
          persistentes, cultivos de ciclo corto y praderas). La información se
          representa en diferentes unidades territoriales.
        </p>
        <h1>Provincias de la región de Coquimbo</h1>
        {Url.map((url, index) => (
          <div>
            <div className="provincias">
              <a key={index} href={`/visualizadores?url=${url.linkElqui}`}>
                <div className="fondoProvincia">
                  <img className="imagenIzquierda" src={provincia1}></img>
                  <img className="imagenDerecha" src={fondo1}></img>
                </div>
                <img className="iconoProvincia" src={icono1}></img>
              </a>
            </div>
            <div className="provincias">
              <a key={index} href={`/visualizadores?url=${url.linkLimari}`}>
                <div className="fondoProvincia">
                  <img className="imagenIzquierda" src={provincia2}></img>
                  <img className="imagenDerecha" src={fondo2}></img>
                </div>
                <img className="iconoProvincia" src={icono2}></img>
              </a>
            </div>
            <div className="provincias">
              <a key={index} href={`/visualizadores?url=${url.linkChoapa}`}>
                <div className="fondoProvincia">
                  <img className="imagenIzquierda" src={provincia3}></img>
                  <img className="imagenDerecha" src={fondo3}></img>
                </div>
                <img className="iconoProvincia" src={icono3}></img>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Panel_Monitoreo;
